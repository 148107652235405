import React from 'react';
import { LargeCheckbox } from 'oemiq-common';
import { ESProcedure } from 'components/locations/MappingProcess/Procedures/ESProcedure';
import { useRow } from 'components/Shared/NewDataTable/Contextes';

export const SelectRowCell = ({
    selected,
    isAll,
    onSelectOne,
}: {
    selected: number[];
    isAll: boolean;
    onSelectOne: (row: ESProcedure) => unknown;
}) => {
    const row = useRow<ESProcedure>();
    const isRemovalPending = row?.stageArea.isDeleted && !row.isDeleted;
    const isRemoved = row?.stageArea.isDeleted && row.isDeleted;

    return (
        <div>
            <LargeCheckbox
                id={`bulk-select-${row.procedureId}`}
                label=""
                className="large-checkbox-check mb-0"
                checked={selected.includes(row.procedureId)}
                disabled={isAll || isRemovalPending || isRemoved}
                onChange={() => onSelectOne(row)}
                readOnly={false}
            />
        </div>
    );
};
