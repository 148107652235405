import React from 'react';
import OemNameBreadcrumb from '../OemNameBreadcrumb';
import { Outlet, useParams } from 'react-router-dom';

export const OemIdNavigationItem = () => {
    const { oemId } = useParams();
    return (
        <>
            <OemNameBreadcrumb
                oemId={oemId}
                link={`/mapping-process/procedures/${oemId}`}
                isCurrent={true}
                withImportJob={false}
            />
            <Outlet />
        </>
    );
};
