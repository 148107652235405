import React from 'react';
import { ProcedureType } from 'hooks/OemModels/MetaModel/ProcedureType';
import { Link, Outlet, useOutlet } from 'react-router-dom';
import { kebabCase } from 'lodash';
import { NavDropdown } from 'react-bootstrap';
import { useOemIdParams } from 'components/locations/MappingProcess/RulesRunner/RulesRunnerTool';

export const MappingProceduresToolModeNavigationItem = ({
    procedureType,
}: {
    procedureType?: ProcedureType | null;
}) => {
    const outlet = useOutlet();
    const oemId = useOemIdParams();
    const buildUrl = (pt: ProcedureType | null) =>
        `/mapping-process/procedures/${oemId}${pt ? `/${kebabCase(pt)}s` : ''}`;

    return (
        <>
            <li className={`breadcrumb-item text-white ${outlet ? '' : 'active'}`} style={{ whiteSpace: 'nowrap' }}>
                <NavDropdown title={procedureType ?? 'All'}>
                    <NavDropdown.Item to={buildUrl(null)} as={Link} active={procedureType === null}>
                        All
                    </NavDropdown.Item>
                    <NavDropdown.Item
                        to={buildUrl(ProcedureType.Procedure)}
                        as={Link}
                        active={procedureType === ProcedureType.Procedure}>
                        Procedures
                    </NavDropdown.Item>
                    <NavDropdown.Item
                        to={buildUrl(ProcedureType.Bulletin)}
                        as={Link}
                        active={procedureType === ProcedureType.Bulletin}>
                        Bulletins
                    </NavDropdown.Item>
                    <NavDropdown.Item
                        to={buildUrl(ProcedureType.PositionStatement)}
                        as={Link}
                        active={procedureType === ProcedureType.PositionStatement}>
                        Position statements
                    </NavDropdown.Item>
                    <NavDropdown.Divider />
                    <NavDropdown.Item to={`/mapping-process/procedures/${oemId}/book-list`} as={Link}>
                        Book list
                    </NavDropdown.Item>
                </NavDropdown>
            </li>
            <Outlet />
        </>
    );
};
