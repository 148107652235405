import React from 'react';
import { Outlet, useNavigate, useOutlet } from 'react-router-dom';

export const MappingProcessProceduresNavigationItem = () => {
    //${isCurrent ? 'active' : ''}
    const navigate = useNavigate();
    const outlet = useOutlet();

    return (
        <ol className="breadcrumb">
            <li className={`breadcrumb-item `} style={{ whiteSpace: 'nowrap' }}>
                <div className="text-white clickable d-inline-block" onClick={() => navigate('/mapping-process')}>
                    Mapping Process
                </div>
            </li>
            <li className={`breadcrumb-item ${outlet ? '' : 'active'}`} style={{ whiteSpace: 'nowrap' }}>
                <div
                    className="text-white clickable d-inline-block"
                    onClick={() => navigate('/mapping-process/procedures')}>
                    Procedures
                </div>
            </li>
            <Outlet />
        </ol>
    );
    return <></>;
};
