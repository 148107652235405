import React, { useEffect, useState } from 'react';
import { Navigate } from 'react-router-dom';
import { LoadingIndicator } from 'oemiq-common';
import authenticationService from './AuthenticationService';
import { ApplicationPaths, QueryParameterNames } from './AuthenticationConstants';

const Authenticated = ({ children }: { children: React.ReactNode }) => {
    const [status, setStatus] = useState({
        ready: false,
        isAuthenticated: false,
    });

    useEffect(() => {
        const subscription = authenticationService.subscribe(() => authenticationChanged());

        const populateAuthenticationState = async () => {
            const authenticated = await authenticationService.isAuthenticated();
            setStatus({
                ready: true,
                isAuthenticated: authenticated,
            });
        };

        const authenticationChanged = async () => {
            await populateAuthenticationState();
        };

        populateAuthenticationState();

        const cleanup = () => {
            authenticationService.unsubscribe(subscription);
        };

        return cleanup;
    }, []);

    const redirectUrl = `${ApplicationPaths.Login}?${QueryParameterNames.ReturnUrl}=${encodeURIComponent(
        window.location.href
    )}`;

    return status.isAuthenticated ? (
        children
    ) : status.ready ? (
        <Navigate replace to={redirectUrl} />
    ) : (
        <LoadingIndicator />
    );
};

export default Authenticated;
