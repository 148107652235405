import { useOemIdParams } from 'components/locations/MappingProcess/RulesRunner/RulesRunnerTool';
import { ProcedureType } from 'hooks/OemModels/MetaModel/ProcedureType';
import { kebabCase } from 'lodash';
import React from 'react';
import { NavDropdown } from 'react-bootstrap';
import { Link, Outlet, useOutlet } from 'react-router-dom';

export const BookListNavigationItem = () => {
    const oemId = useOemIdParams();
    const buildUrl = (pt: ProcedureType | null) =>
        `/mapping-process/procedures/${oemId}${pt ? `/${kebabCase(pt)}s` : ''}`;
    const outlet = useOutlet();

    return (
        <>
            <li className={`breadcrumb-item ${outlet ? '' : 'active'}`} style={{ whiteSpace: 'nowrap' }}>
                <NavDropdown title="Book list">
                    <NavDropdown.Item to={`/mapping-process/procedures/${oemId}/book-list`} as={Link}>
                        Book list
                    </NavDropdown.Item>
                    <NavDropdown.Divider />
                    <NavDropdown.Item to={buildUrl(null)} as={Link}>
                        All
                    </NavDropdown.Item>
                    <NavDropdown.Item to={buildUrl(ProcedureType.Procedure)} as={Link}>
                        Procedures
                    </NavDropdown.Item>
                    <NavDropdown.Item to={buildUrl(ProcedureType.Bulletin)} as={Link}>
                        Bulletins
                    </NavDropdown.Item>
                    <NavDropdown.Item to={buildUrl(ProcedureType.PositionStatement)} as={Link}>
                        Position statements
                    </NavDropdown.Item>
                </NavDropdown>
            </li>
            <Outlet />
        </>
    );
};
