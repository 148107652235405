import React, { useState } from 'react';
import { PrimaryButton } from 'oemiq-common';
import ReactModal from 'components/Shared/ReactModal';
import { OemMetadataView } from 'hooks/OemModels/MetaModel/BuildOemMetadata';
import OemProceduresTable from 'components/OemProceduresTable/OemProceduresTable';
import useProcedures from 'components/OemProceduresTable/items/useProcedures';
import ViewProcedureModal from 'components/Modals/ViewProcedureModal/ViewProcedureModal';
import ViewProcedureMappingHistoryModal from 'components/Modals/ViewProcedureMappingHistoryModal/ViewProcedureMappingHistoryModal';
import { HistoryModalProcedure } from 'components/Modals/ViewProcedureMappingHistoryModal/ViewProcedureMappingHistoryModal';
import { Procedure } from 'types/procedureTypes';
import { FilterClause } from '../../Procedures/MappingProceduresTool';
import { DataSource } from '../../../../../hooks/OemModels/MetaModel/DataSource';

interface PreviewProceduresModalProps {
    filters: FilterClause[];
    oemId: number;
    oemMetadataView: OemMetadataView;
    isPreviewProceduresModalOpen: boolean;
    closePreviewProceduresModal: () => void;
    columnSettings: { id: string; isHidden: boolean; text: string; width: string }[];
    handleSorting: () => void;
    totalCount: number;
}

const PreviewProceduresModal = ({
    filters,
    oemId,
    oemMetadataView,
    isPreviewProceduresModalOpen,
    closePreviewProceduresModal,
    columnSettings,
    handleSorting,
    totalCount,
}: PreviewProceduresModalProps) => {
    const {
        data,
        hasMoreData,
        loading: isLoadingProcedures,
        loadMoreCallback,
    } = useProcedures(filters, null, null, oemId, false, DataSource.SQL);

    const [modalProcedure, setModalProcedure] = useState<Procedure | null>(null);
    const [historyModalProcedure, setHistoryModalProcedure] = useState<HistoryModalProcedure | null>(null);

    return (
        <>
            <ViewProcedureModal
                procedureId={modalProcedure?.procedureId || null}
                resetProcedureId={() => setModalProcedure({ ...modalProcedure, procedureId: null })}
                headerButtons={undefined}
            />
            <ViewProcedureMappingHistoryModal
                procedure={historyModalProcedure}
                resetProcedureId={() => setHistoryModalProcedure(null)}
            />

            <ReactModal
                id="preview-procedures-modal"
                container={undefined}
                isOpen={isPreviewProceduresModalOpen}
                toggle={closePreviewProceduresModal}
                headerComponent={<PreviewProceduresModalHeader />}
                bodyComponent={
                    <PreviewProceduresModalBody
                        data={data}
                        hasMoreData={hasMoreData}
                        oemMetadataView={oemMetadataView}
                        columnSettings={columnSettings}
                        isLoadingProcedures={isLoadingProcedures}
                        handleSorting={handleSorting}
                        loadMoreCallback={loadMoreCallback}
                        setModalProcedure={setModalProcedure}
                        setHistoryModalProcedure={setHistoryModalProcedure}
                        totalCount={totalCount}
                    />
                }
                footerComponent={
                    <PreviewProceduresModalFooter closePreviewProceduresModal={closePreviewProceduresModal} />
                }
                className={'modal-xl sticky-footer'}
            />
        </>
    );
};

const PreviewProceduresModalHeader = () => (
    <div className="d-flex justify-content-between" style={{ position: 'sticky', top: '0px' }}>
        <span>Procedures that meet the selected conditions of the rule</span>
    </div>
);

interface PreviewProceduresModalBodyProps {
    data;
    hasMoreData: boolean;
    oemMetadataView: OemMetadataView;
    columnSettings: { id: string; isHidden: boolean; text: string; width: string }[];
    isLoadingProcedures: boolean;
    handleSorting: () => void;
    loadMoreCallback: () => void;
    setModalProcedure: (procId: Procedure | null) => void;
    setHistoryModalProcedure: (proc: HistoryModalProcedure | null) => void;
    totalCount: number;
}

const PreviewProceduresModalBody = ({
    data,
    hasMoreData,
    oemMetadataView,
    columnSettings,
    isLoadingProcedures,
    handleSorting,
    loadMoreCallback,
    setModalProcedure,
    setHistoryModalProcedure,
    totalCount,
}: PreviewProceduresModalBodyProps) => (
    <>
        <OemProceduresTable
            data={data}
            hasMoreData={hasMoreData}
            oemMetadataView={oemMetadataView}
            style={{ height: 'calc(100vh - 220px)' }}
            columnSettings={columnSettings}
            isLoading={isLoadingProcedures}
            onSorting={handleSorting}
            loadMoreCallback={loadMoreCallback}
            onClickModalProcedure={setModalProcedure}
            onclickHistoryModalProcedure={setHistoryModalProcedure}
            isEditable={false}
        />
        <footer id="preview-procedures-status-bar" style={{ position: 'sticky', bottom: '40px' }}>
            {data.length} procedures of {totalCount}
        </footer>
    </>
);

interface PreviewProceduresModalFooterProps {
    closePreviewProceduresModal: () => void;
}

const PreviewProceduresModalFooter = ({ closePreviewProceduresModal }: PreviewProceduresModalFooterProps) => (
    <PrimaryButton
        id="preview-procedures-modal-close"
        className="me-3"
        type="button"
        onClick={closePreviewProceduresModal}>
        Close
    </PrimaryButton>
);

export default PreviewProceduresModal;
