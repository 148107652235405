import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useLocation, useParams, createSearchParams } from 'react-router-dom';
import { PrimaryButton } from 'oemiq-common';
import ModalCreateMappingRule from './ModalCreateMappingRule/ModalCreateMappingRule';
import ModalOperations, { OperationTypes, OperationSite } from '../../../Shared/ModalOperations/ModalOperations';
import ModalRun from './ModalRun/ModalRun';
import useModal from 'hooks/useModal';
import { AccessControlContext } from 'components/Shared/AccessControl/AccessControl';
import MappingRulesTable from './MappingRulesTable';
import TableFilters from 'components/Shared/TableFilters/TableFilters';
import SignalRHub from 'hooks/signalR/SignalRHub';
import useSignalRCallbacks from './hooks/useSignalRCallbacks';
import { MappingDefinitionsContext } from 'contexts/MappingDefinitionsContext';
import useOemService from 'hooks/OemModels/useOemService';
import { NotificationsContext } from 'components/Shared/Notifications/Notifications';
import { useOemDataModel } from './hooks/OemRulesMetadata';
import { createFiltersFromBasicData, operatorsMap } from 'components/Shared/TableFilters/tableFilterHelpers';
import useLoadOemBooks from './hooks/useLoadOemBooks';
import useTableActions from './hooks/useTableActions';
import { buildTableConfiguration } from './buildTableConfiguration';
import { createLabeledArray } from './Helpers';
import { TemporaryFlags } from '../temporaryFlags';

import { OemId } from 'helpers/OemId';
import { mapSearchablePropertiesToFilters } from '../Procedures/buildFiltersConfiguration';
import { buildProcedureProperty } from 'hooks/OemModels/MetaModel/ProcedureProperty';
import { PROPERTY_TYPE } from 'hooks/OemModels/MetaModel/PropertyType';
import { LoadingContext } from 'components/Layout';
import {
    queueUndoRule,
    requestCreateNewMappingRule,
    requestMappingRuleStatistics,
    requestMappingRules,
    requestRemoveMappingRule,
    requestRunMappingRules,
    requestSetActiveMappingRule,
} from 'api/RepairProcedures/RepairProcedureMappingRuleApi';
import { sqlClauseTranslator } from 'api/RepairProcedures/OemProceduresApiService';
import { RuleableOperatorBackendEnumValue } from '../RulesCreator/RulableValuePicker/types';

const SupportedOemIds = [
    OemId.Ford,
    OemId.Chrysler,
    OemId.Toyota,
    OemId.Nissan,
    OemId.GMC,
    OemId.Honda,
    OemId.Volkswagen,
    OemId.Subaru,
    OemId.Hyundai,
    OemId.OEMiQ,
];

type MappingRule = {
    isActive: boolean;
    isDeleted: boolean;
    groupIds: number[];
    typeId: number | null;
    parsedGroupIds: number[];
    isRunned: boolean;
    createDate: string;
    updateDate: string;
    properties: {
        [propertyName: string]: {
            operator: RuleableOperatorBackendEnumValue;
            value: string;
        };
    };
    statistics?: {
        procedureCount: number;
    };
};

const RulesRunnerBody = ({ oemId }: { oemId: OemId }) => {
    const routeParams = useLocation();

    const { userInfo } = useContext(AccessControlContext);

    const [rules, setRules] = useState<MappingRule[]>([]);
    const [totalRulesCount, setTotalRulesCount] = useState(0);
    const [isLoading, setIsLoading] = useState(false);
    const isAll = useRef(false);
    const [pageNumber, setPageNumber] = useState(1);
    const [selected, setSelected] = useState([]);

    const { groups, types } = useContext(MappingDefinitionsContext);
    const { notifications } = useContext(NotificationsContext);
    const { hasAccess } = useContext(AccessControlContext);
    const { incrementLoading, decrementLoading } = useContext(LoadingContext);
    const oemModel = useOemDataModel(oemId);

    const dataModel = useMemo(() => {
        if (TemporaryFlags.displayConditionForGmcBulletinRules && oemId == OemId.GMC) {
            oemModel.columns.push({ fieldName: 'bulletinType', displayName: 'Bulletin Type' });
        }
        return oemModel;
    }, [oemId, oemModel]);

    const filtersConfiguration = useMemo(
        () =>
            mapSearchablePropertiesToFilters([
                ...dataModel.columns.map(c =>
                    buildProcedureProperty({
                        id: c.fieldName,
                        name: `properties.${c.fieldName}.value`,
                        displayName: c.displayName,
                        type: PROPERTY_TYPE.string,
                    })
                ),
                buildProcedureProperty({
                    id: 'mappingRuleGroupsView',
                    name: 'groupIds',
                    displayName: 'Groups',
                    type: PROPERTY_TYPE.mappingRuleGroupView,
                }),
                buildProcedureProperty({
                    id: 'TypeName',
                    name: 'typeId',
                    displayName: 'Type Name',
                    type: PROPERTY_TYPE.typeName,
                }),
                buildProcedureProperty({ name: 'isActive', displayName: 'Active', type: PROPERTY_TYPE.bool }),
                buildProcedureProperty({ name: 'createDate', displayName: 'Create Date', type: PROPERTY_TYPE.date }),
                buildProcedureProperty({
                    name: dataModel.keyField,
                    displayName: 'Id',
                    type: PROPERTY_TYPE.number,
                }),
            ]),
        [dataModel.columns, dataModel.keyField]
    );
    const [filters, setFilters] = useState(
        routeParams.state?.filters ? createFiltersFromBasicData(routeParams.state.filters, filtersConfiguration) : []
    );

    const { oemMetadataView } = useOemService(oemId);

    const handleCreateRule = useCallback(
        async formValues => {
            const DEFAULT_OPERATOR = RuleableOperatorBackendEnumValue.Equal;
            try {
                incrementLoading();
                const propValues = {
                    ...dataModel.columns
                        .filter(f => formValues[f.fieldName])
                        .reduce((acc, p) => {
                            return {
                                ...acc,
                                [p.fieldName]: { value: formValues[p.fieldName], operator: DEFAULT_OPERATOR },
                            };
                        }, {}),
                };
                const newRuleRequest = {
                    ...propValues,
                    groupIds: formValues.groupIds,
                    typeId: formValues.typeId,
                    procedureTypeId: TemporaryFlags.procedureTypeIdForProcedure,
                };
                const newRuleDisplay: MappingRule = {
                    properties: { ...propValues },
                    groupIds: formValues.groupIds,
                    typeId: formValues.typeId,
                    parsedGroupIds: formValues.groupIds,
                    isActive: false,
                    isDeleted: false,
                    isRunned: false,
                    createDate: '',
                    updateDate: '',
                };
                const mappingRuleId = await requestCreateNewMappingRule(oemId, newRuleRequest);
                newRuleDisplay.isActive = true;
                newRuleDisplay[dataModel.keyField] = mappingRuleId;
                newRuleDisplay.createDate = new Date().toISOString();
                setRules([newRuleDisplay, ...rules]);
                notifications.pushSuccess('New mapping rule created');
            } catch (error) {
                notifications.pushExceptionDanger(error);
            } finally {
                decrementLoading();
            }
        },
        [dataModel.columns, dataModel.keyField, decrementLoading, incrementLoading, notifications, oemId, rules]
    );

    const runRulesCallback = useCallback(
        async (ruleIds, bookIds, closeModalCallback) => {
            try {
                await requestRunMappingRules(oemId, ruleIds, bookIds);
                setSelected([]);
                closeModalCallback && closeModalCallback();
                notifications.pushSuccess('Selected rules will run for selected books');
            } catch (error) {
                notifications.pushExceptionDanger(error);
            }
        },
        [notifications, oemId]
    );

    const getMappingRules = useCallback(async () => {
        try {
            setIsLoading(true);
            incrementLoading();
            const { newRules, totalCount, all } = await requestMappingRules(
                oemId,
                pageNumber,
                sqlClauseTranslator.filterBy(filters, null)
            );
            setRules(rules => {
                const page = newRules.map(rule => ({
                    ...rule,
                    groupIds: rule.parsedGroupIds ?? [],
                }));
                return pageNumber === 1 ? [...page] : [...rules, ...page];
            });
            setTotalRulesCount(totalCount);
            isAll.current = all;
        } catch (error) {
            notifications.pushExceptionDanger(error);
        } finally {
            decrementLoading();
            setIsLoading(false);
        }
    }, [incrementLoading, oemId, pageNumber, filters, notifications, decrementLoading]);

    const toggleActiveMappingRule = useCallback(
        async rule => {
            try {
                incrementLoading();
                await requestSetActiveMappingRule(oemId, rule[dataModel.keyField], !rule.isActive);

                setRules(
                    rules.map(r => {
                        if (r[dataModel.keyField] === rule[dataModel.keyField]) {
                            r.isActive = !r.isActive;
                        }
                        return r;
                    })
                );

                notifications.pushSuccess(
                    `Rule ${rule[dataModel.keyField]} was set to ${!rule.isActive ? 'active' : 'inactive'}`
                );
            } catch (error) {
                notifications.pushExceptionDanger(error);
            } finally {
                decrementLoading();
            }
        },
        [dataModel.keyField, decrementLoading, incrementLoading, oemId, rules, notifications]
    );

    const removeMappingRule = useCallback(
        async rule => {
            try {
                incrementLoading();

                await requestRemoveMappingRule(oemId, rule[dataModel.keyField]);

                setRules(rules.filter(r => r[dataModel.keyField] !== rule[dataModel.keyField]));

                notifications.pushSuccess(`Rule ${rule[dataModel.keyField]} was successfully removed`);
            } catch (error) {
                notifications.pushExceptionDanger(error);
            } finally {
                decrementLoading();
            }
        },
        [dataModel.keyField, decrementLoading, incrementLoading, oemId, rules, notifications]
    );

    const queueUndoMappingRule = useCallback(
        async (oemId, ruleId) => {
            try {
                await queueUndoRule(oemId, ruleId, userInfo.userId);
                notifications.pushSuccess('Selected rule will be undone.');
            } catch (error) {
                notifications.pushExceptionDanger(error);
            }
        },
        [notifications, userInfo.userId]
    );

    const loadMoreCallback = useCallback(() => {
        !isAll.current && setPageNumber(p => p + 1);
    }, []);

    const refreshRuleStatistics = useCallback(
        async ruleId => {
            try {
                const statistics = await requestMappingRuleStatistics(oemId, ruleId);
                setRules(rules =>
                    rules.map(r => {
                        if (r[dataModel.keyField] === ruleId) {
                            r.statistics = statistics;
                        }
                        return r;
                    })
                );
            } catch (error) {
                notifications.pushExceptionDanger(error);
            }
        },
        [dataModel.keyField, notifications, oemId]
    );

    const setIsRunnedFlag = useCallback(
        ruleIds => {
            setRules(rules =>
                rules.map(r => {
                    if (ruleIds.includes(r[dataModel.keyField])) {
                        return { ...r, isRunned: true };
                    }
                    return r;
                })
            );
        },
        [dataModel.keyField]
    );

    useEffect(() => {
        getMappingRules();
    }, [pageNumber, getMappingRules]);

    useEffect(() => {
        setPageNumber(1);
    }, [filters]);

    const books = useLoadOemBooks(oemId);
    const tableRules = useMemo(
        () =>
            rules.map(rule => ({
                ...rule,
                labeledGroups: createLabeledArray(rule.groupIds, groups, 'regionId', 'label').map(labelText => ({
                    labelText,
                })),
                labeledType: createLabeledArray([rule.typeId], types, 'oemIqSectionId', 'text').map(labelText => ({
                    labelText,
                })),
                labeledMetadata: dataModel.columns
                    .map(column => {
                        if (
                            rule.properties &&
                            rule.properties[column.fieldName] &&
                            rule.properties[column.fieldName].value !== null
                        ) {
                            return {
                                labelText: column.displayName,
                                ruleConditionPillText: rule.properties[column.fieldName].operator,
                                valuePillText: rule.properties[column.fieldName].value,
                            };
                        }
                    })
                    .filter(metadata => metadata !== undefined),
            })),
        [dataModel.columns, groups, rules, types]
    );

    const navigateToEngine = useCallback(
        (oemId, rules) => {
            if (!oemMetadataView) {
                notifications.pushWarning('Navigation to Procedures page is not supported for current oem');
                return;
            }

            const filters = oemMetadataView.ruleable
                .map(f => {
                    const field = oemMetadataView.metadata.properties[f];
                    return {
                        id: field.name,
                        operator: operatorsMap[rules.properties[field.rulePropertyName].operator],
                        value: rules.properties[field.rulePropertyName].value,
                        valueList: [rules.properties[field.rulePropertyName].value],
                    };
                })
                .filter(filter => filter.value !== null);

            if (rules.isRunned) {
                const mappingRuleId = rules[dataModel.keyField];
                filters.push({
                    id: 'mappingRuleId',
                    operator: 'mappedBy',
                    value: { rulesIds: [{ value: mappingRuleId, label: mappingRuleId }] },
                    valueList: [mappingRuleId],
                });
            }
            const searchParamsFilters = createSearchParams(
                filters.reduce((p, c) => ({ ...p, [c.id]: JSON.stringify(c) }), {})
            );
            window.open(`/mapping-process/procedures/${oemId}?${searchParamsFilters.toString()}`, '_blank');
        },
        [dataModel.keyField, notifications, oemMetadataView]
    );

    const setFiltersCallback = useCallback(
        e => {
            setSelected([]);
            setFilters(e);
        },
        [setFilters, setSelected]
    );

    const headers = useMemo(() => buildTableConfiguration(dataModel, hasAccess), [dataModel, hasAccess]);
    const actions = useTableActions(
        oemId,
        dataModel,
        hasAccess,
        toggleActiveMappingRule,
        removeMappingRule,
        navigateToEngine,
        queueUndoMappingRule
    );

    const {
        isModalOpen: isOperationsModalOpen,
        openModal: openOperationsModal,
        closeModal: closeOperationsModal,
    } = useModal();

    const { isModalOpen: isRunModalOpen, openModal: openRunModal, closeModal: closeRunModal } = useModal();

    const {
        isModalOpen: isCreateRuleModalOpen,
        openModal: openCreateRuleModal,
        closeModal: closeCreateRuleModal,
    } = useModal();

    const callbacks = useSignalRCallbacks(refreshRuleStatistics, setIsRunnedFlag);

    return (
        <SignalRHub hub={'mappingRules'} callbacks={callbacks}>
            <div className="d-flex">
                <h2 className="flex-grow-1">Mapping Rules Runner</h2>
                <PrimaryButton
                    id="open-run-all-modal"
                    className="me-3"
                    type="button"
                    disabled={selected.length > 0 || !hasAccess('rule.run')}
                    onClick={openRunModal}>
                    Run All Rules
                </PrimaryButton>
                <PrimaryButton
                    id="open-run-selected-modal"
                    className="me-3"
                    type="button"
                    disabled={selected.length < 1 || !hasAccess('rule.run')}
                    onClick={openRunModal}>
                    Run Selected Rules
                </PrimaryButton>
                <PrimaryButton id="open-operations-modal" className="me-3" type="button" onClick={openOperationsModal}>
                    Mapping Operations
                </PrimaryButton>
                <PrimaryButton
                    id="open-create-update-mapping-modal"
                    className="me-3"
                    type="button"
                    disabled={!hasAccess('rule.create')}
                    onClick={openCreateRuleModal}>
                    Create New Mapping Rule
                </PrimaryButton>
            </div>
            <TableFilters configuration={filtersConfiguration} filters={filters} setFilters={setFiltersCallback} />
            <MappingRulesTable
                dataModel={dataModel}
                headers={headers}
                actions={actions}
                rules={tableRules}
                selected={selected}
                setSelected={setSelected}
                isLoading={isLoading}
                loadMoreCallback={loadMoreCallback}
            />

            <footer id="status-bar" className="d-flex fixed-bottom py-2 border-top">
                <div className="m-1 justify-content-between align-items-center px-3">
                    {rules.length} of {totalRulesCount} ({selected.length} selected)
                </div>
            </footer>

            {isCreateRuleModalOpen && (
                <ModalCreateMappingRule
                    oemId={oemId}
                    isCreateRuleModalOpen={isCreateRuleModalOpen}
                    closeCreateRuleModalCallback={closeCreateRuleModal}
                    submitCallback={handleCreateRule}
                />
            )}
            {isOperationsModalOpen && (
                <ModalOperations
                    oemId={oemId}
                    operationTypes={[OperationTypes.ApplyRule, OperationTypes.UndoRule]}
                    modalType={OperationSite.RulesRunner}
                    isOperationsModalOpen={isOperationsModalOpen}
                    closeOperationsModalCallback={closeOperationsModal}
                />
            )}
            {isRunModalOpen && (
                <ModalRun
                    books={books}
                    ruleIds={selected.map(rule => rule.id)}
                    isOpen={isRunModalOpen}
                    closeRunModalCallback={closeRunModal}
                    runRulesCallback={runRulesCallback}
                />
            )}
        </SignalRHub>
    );
};

const RulesRunnerTool = () => {
    const oemId = useOemIdParams();
    const isOemSupported = SupportedOemIds.includes(oemId);

    return (
        <div className="pt-3 pe-3 ps-3 d-flex flex-column">
            {isOemSupported ? (
                <RulesRunnerBody oemId={oemId as OemId} />
            ) : (
                <div className="text-danger">Selected OEM is not supported at that moment. OemId: {oemId}</div>
            )}
        </div>
    );
};

export default RulesRunnerTool;

export const useOemIdParams = () => {
    const { oemId } = useParams();
    return useMemo(() => parseInt(oemId, 10), [oemId]);
};
